<template>
    <div class="clue_box">
        <div class="search-box">
            <div class="search-one">
                <p class="left">
                    <!-- <span>线索产品</span>
                    <el-select
                        v-model="searchVal.productClue"
                        size="small"
                        style="width: 120px"
                        clearable
                        placeholder="请选择"
                        @change="getTableData()"
                    >
                        <el-option
                            v-for="item in $searchForm.productCategory()"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select> -->
                    <clue-product
                        ref="clueproduct"
                        @changeProductId="changeProductId"
                        :productWidth="120"
                    ></clue-product>
                    <span>创建时间</span>
                    <el-radio-group v-model="dateChoice" size="small">
                        <el-radio-button
                            label="今天"
                            @click.native.prevent="clickitem('今天')"
                        ></el-radio-button>
                        <el-radio-button
                            label="7天内"
                            @click.native.prevent="clickitem('7天内')"
                        ></el-radio-button>
                        <el-radio-button
                            label="本月"
                            @click.native.prevent="clickitem('本月')"
                        ></el-radio-button>
                    </el-radio-group>
                    <el-date-picker
                        v-model="searchDate"
                        size="small"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        style="width: 240px"
                        placeholder="选择日期"
                        value-format="yyyy-MM-dd"
                        :picker-options="expireTimeOption"
                        clearable
                        @change="expireTime"
                    >
                    </el-date-picker>
                    <span>查询</span>
                    <el-input
                        size="small"
                        style="width: 180px"
                        placeholder="公司名称、电话、编号"
                        v-model.trim="searchVal.name"
                        clearable
                    >
                    </el-input>
                    <el-button
                        @click="getTableData2()"
                        type="primary"
                        style="margin-right: 0"
                        size="small"
                        icon="el-icon-search"
                    ></el-button>
                </p>
                <p>
                    <el-button
                        class="right"
                        type="primary"
                        style="margin-left: 29px"
                        size="small"
                        @click="getTableData()"
                        >查询</el-button
                    >
                    <el-button
                        class="right"
                        @click="searchEmpty()"
                        type="primary"
                        style="
                            margin: 0;
                            background: none;
                            border-color: #d9d9d9;
                            color: #333333;
                            margin-right: 20px;
                        "
                        icon="el-icon-refresh-left"
                        size="small"
                        >重置</el-button
                    >
                </p>
            </div>
            <!-- <div class="search-two">
                <p class="left">
                    <span v-if="btnP.depSearch">部门</span>
                    <el-select
                        v-if="btnP.depSearch"
                        v-model="searchVal.departmentId"
                        size="small"
                        filterable
                        style="width: 120px"
                        clearable
                        placeholder="请选择"
                        @change="changeDepartmentId"
                    >
                        <el-option
                            v-for="item in departmentList"
                            :key="item.id"
                            :label="item.departmentName"
                            :value="item.id"
                        >
                        </el-option>
                    </el-select>
                    <span v-if="btnP.userSearch">成员</span>
                    <el-select
                        v-if="btnP.userSearch"
                        filterable
                        style="margin-left: 10px; width: 120px"
                        v-model="searchVal.adminId"
                        size="small"
                        clearable
                        placeholder="请选择"
                        @change="getTableData"
                    >
                        <el-option
                            v-for="item in userName"
                            :key="item.id"
                            :label="item.nickName"
                            :value="item.id"
                        >
                        </el-option>
                    </el-select>

                    <span v-if="btnP.launch">投放站点</span>
                    <el-select
                        v-if="btnP.launch"
                        v-model="searchVal.launchId"
                        size="small"
                        style="width: 120px"
                        clearable
                        filterable
                        placeholder="请选择"
                        @change="getTableData"
                    >
                        <el-option
                            :label="item.account"
                            v-for="item in getLaunchList"
                            :key="item.id"
                            :value="item.id"
                        >
                        </el-option>
                    </el-select>
                </p>
            </div> -->
        </div>
        <div class="table-title">
            <p>
                <span style="flex: 1">阿里市场线索</span>
                <el-button
                    v-if="btnP.addClue"
                    type="primary"
                    style="
                        background: #fff;
                        border-radius: 2px;
                        padding: 0 12px 0 12px;
                        height: 24px;
                        color: #333333;
                        border-color: #d9d9d9;
                        font-size: 12px;
                    "
                    @click="onAdd()"
                >
                    <i class="el-icon-plus"></i>
                    添加线索</el-button
                >
            </p>
        </div>
        <div class="table">
            <Table
                ref="table"
                @distributionDep="distributionDep"
                @onDetail="onDetail"
                @tabDistributionDep="tabDistributionDep"
                @onRevoke="onRevoke"
                @onDistributionUser="onDistributionUser"
                @telSearch="telSearch"
                @totalNub="totalNub"
                @onDealings="onDealings"
            ></Table>
        </div>
        <div class="page-box">
            <el-pagination
                v-if="isPage"
                @current-change="handleCurrentChange"
                @size-change="handleSizeChange"
                :current-page.sync="currentPage"
                :page-size="pagesize"
                :page-sizes="[10, 20, 50]"
                layout="total,sizes,prev, pager, next, jumper"
                :total="total"
            ></el-pagination>
        </div>
        <!-- 分配部门 -->
        <el-dialog
            :visible.sync="dialogDistribution"
            width="560px"
            :before-close="handleClose"
            append-to-body
            class="dialog_add_customer"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    分配部门
                </div>
            </template>
            <div class="dialog_info">
                <el-form
                    label-position="left"
                    style="margin: 0 auto; width: 80%"
                    size="small"
                >
                    <p class="info">
                        <label>线索产品</label>
                        {{
                            $tableDataHandle.productCategory(
                                tableRow.productClue
                            )
                        }}
                    </p>
                    <p class="info">
                        <label>客户名称</label>
                        {{ tableRow.companyName || '- -' }}
                    </p>
                    <el-form-item label="分配部门" required>
                        <el-select
                            v-model="followDepartmentId"
                            size="small"
                            filterable
                            style="width: 270px"
                            placeholder="请选择或请输入搜索部门"
                        >
                            <el-option
                                v-for="item in departmentList"
                                :key="item.id"
                                :label="item.departmentName"
                                :value="item.id"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer" class="dialog_footer">
                <el-button @click="handleClose" round size="mini"
                    >取 消</el-button
                >
                <el-button
                    style="background: #2370eb"
                    type="primary"
                    @click="subDistribution"
                    round
                    size="mini"
                    >确 认</el-button
                >
            </div>
        </el-dialog>
        <!-- 线索撤回 -->
        <el-dialog
            :visible.sync="dialogRevoke"
            width="560px"
            :before-close="handleClose"
            append-to-body
            class="dialog_add_customer"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    撤回线索
                </div>
            </template>
            <p class="dl_con">
                <i class="el-icon-warning-outline"></i>
                即将撤回选择的已分配线索，是否确认？
            </p>
            <div slot="footer" class="dialog_footer">
                <el-button @click="handleClose" round size="mini"
                    >取 消</el-button
                >
                <el-button
                    style="background: #2370eb"
                    type="primary"
                    @click="subRevoke"
                    round
                    size="mini"
                    >确 认</el-button
                >
            </div>
        </el-dialog>
        <!-- 分配负责人 -->
        <el-dialog
            :visible.sync="dialogUser"
            width="560px"
            :before-close="handleClose"
            append-to-body
            class="dialog_add_customer"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    分配负责人
                </div>
            </template>
            <div class="dialog_info">
                <el-form
                    label-position="left"
                    style="margin: 0 auto; width: 80%"
                    size="small"
                >
                    <p class="info">
                        <label>线索产品</label>
                        {{
                            $tableDataHandle.productCategory(
                                tableRow.productClue
                            )
                        }}
                    </p>
                    <p class="info">
                        <label>客户名称</label>
                        {{ tableRow.companyName || '- -' }}
                    </p>
                    <el-form-item label="负责人" required>
                        <el-select
                            v-model="followAdminId"
                            size="small"
                            filterable
                            style="width: 270px"
                            placeholder="请选择或请输入搜索负责人"
                        >
                            <el-option
                                v-for="item in chargeUser"
                                :key="item.id"
                                :label="item.nickName"
                                :value="item.id"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer" class="dialog_footer">
                <el-button @click="handleClose" round size="mini"
                    >取 消</el-button
                >
                <el-button
                    style="background: #2370eb"
                    type="primary"
                    @click="subDistributionUser"
                    round
                    size="mini"
                    >确 认</el-button
                >
            </div>
        </el-dialog>
        <!-- 往来记录 -->
        <el-drawer
            :visible.sync="dialogDealing"
            :direction="direction"
            :before-close="handleClose"
            size="880px"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 24px;
                    "
                >
                    往来记录
                </div>
            </template>
            <Dealings
                @dealingAdd="dealingAdd"
                @close="handleClose"
                @addExplain="addExplain"
                ref="dealings"
            />
        </el-drawer>
    </div>
</template>

<script>
import Table from '../table/table.vue';
import { departmentList } from '@/api/admin/framework/framework.js';
import { roleList } from '@/api/customer/customer.js';
import { launchList } from '@/api/user/launch/manage.js';
import { addClueDealing } from '@/api/user/clue.js';
import { clueDealingList } from '@/api/clue/depClue';
import { intercourseAdd } from '@/api/clue/meClue';
import { getClue, updateClue, allotDepartment } from '@/api/clue/depClue.js';
import AddDealing from '../../depClue/detail/components/addDealing.vue';
import Dealings from '../../../database/dealings/dealings.vue';
import clueProduct from '../../../../components/clueProduct.vue';
export default {
    name: '',
    props: {},
    data() {
        return {
            searchVal: {
                followAdminId: '',
                name: '',
                adminId: '',
            },
            form: {},
            btnP: {},
            pagesize: 20,
            total: 0,
            currentPage: 1,
            isDistribution: false,
            direction: 'rtl',
            dialogDistribution: false,
            dialogRevoke: false,
            dialogUser: false,
            dialogDealing: false,
            isPage: false,
            dateChoice: '',
            searchDate: [],
            expireTimeOption: {
                disabledDate(date) {
                    // disabledDate 文档上：设置禁用状态，参数为当前日期，要求返回 Boolean
                    return date.getTime() >= Date.now();
                },
            },
            userName: [],
            departmentList: [],
            choiseData: [],
            getLaunchList: [],
            chargeUser: [],
            tableRow: {},
            followAdminId: '',
            followDepartmentId: '',
        };
    },
    components: {
        Table,
        AddDealing,
        Dealings,
        clueProduct,
    },
    mounted() {
        this.getBtn();
        this.isPage = true;
    },
    methods: {
        totalNub(i) {
            this.total = i;
        },
        // 获取按钮权限
        getBtn() {
            var btnArr = JSON.parse(sessionStorage.getItem('isButArr'));
            let btn = {};
            for (let i in btnArr) {
                btn['' + btnArr[i].route] = true;
                if (btnArr[i].children && btnArr[i].children.length > 0) {
                    btnArr[i].children.forEach((item) => {
                        btn['' + item.route] = true;
                        if (item.children && item.children.length > 0) {
                            item.children.forEach((itm) => {
                                btn['' + itm.route] = true;
                            });
                        }
                    });
                }
            }
            this.btnP = JSON.parse(JSON.stringify(btn));
            if (btn.depSearch || btn.disDep) {
                this.getDepartmentList();
            }
            if (!btn.depSearch && btn.userSearch) {
                this.getUserList(sessionStorage.getItem('departmentId'));
            }
            if (btn.launch) {
                this.launchList();
            }
            if (btn.disUser) {
                this.getChargeUser();
            }
            this.getPageData();
            this.$refs.clueproduct.getData();
            this.getData();
        },
        getPageData() {
            if (
                sessionStorage.getItem('pageData') &&
                JSON.parse(sessionStorage.getItem('pageData'))
            ) {
                this.currentPage = JSON.parse(
                    sessionStorage.getItem('pageData')
                ).pageNum;
                this.pagesize = JSON.parse(
                    sessionStorage.getItem('pageData')
                ).pageSize;
                this.searchVal =
                    JSON.parse(sessionStorage.getItem('pageData')).searchVal ||
                    {};
                this.$refs.clueproduct.$data.productId =
                    this.searchVal.productClue;
                this.searchDate =
                    JSON.parse(sessionStorage.getItem('pageData')).searchDate ||
                    '';
                this.dateChoice =
                    JSON.parse(sessionStorage.getItem('pageData')).dateChoice ||
                    '';
                if (
                    JSON.parse(sessionStorage.getItem('pageData')).searchVal
                        .departmentId
                ) {
                    if (this.btnP.depSearch) {
                        this.getUserList(
                            JSON.parse(sessionStorage.getItem('pageData'))
                                .searchVal.departmentId
                        );
                    }
                }
                sessionStorage.removeItem('pageData');
                this.isPage = true;
            } else {
                this.isPage = true;
            }
        },
        // 获取部门成员
        getUserList(departmentId) {
            this.searchVal.followAdminId = '';
            let data = {
                param: {
                    departmentId,
                },
            };
            roleList(data).then((res) => {
                this.userName = res.data;
            });
        },
        // 获取部门
        getDepartmentList() {
            let data = {
                param: {},
            };
            departmentList(data).then((res) => {
                if (res.code == 200) {
                    this.departmentList = res.data;
                }
            });
        },
        changeDepartmentId() {
            this.getTableData();
            this.getUserList(this.searchVal.departmentId);
        },
        // 获取投放列表(投放站点)
        launchList() {
            let data = {
                param: {},
                pageNum: 0,
                pageSize: 0,
            };
            launchList(data).then((res) => {
                if (res.code == 200) {
                    this.getLaunchList = res.data.list;
                }
            });
        },
        onAdd() {
            this.$router.push('/addAliclue');
        },
        clickSourceCategory(val) {
            val == this.searchVal.sourceCategory
                ? (this.searchVal.sourceCategory = '')
                : (this.searchVal.sourceCategory = val);
            this.searchVal = JSON.parse(JSON.stringify(this.searchVal));
            this.getTableData();
        },
        getTableData() {
            this.currentPage = 1;
            this.getData();
        },
        getTableData2() {
            this.currentPage = 1;
            this.searchDate = '';
            this.dateChoice = '';
            for (const key in this.searchVal) {
                if (key != 'name') {
                    this.searchVal[key] = '';
                }
            }
            this.getData();
        },
        changeProductId(val) {
            this.pagesize = 20;
            this.currentPage = 1;
            this.searchVal.productClue = val;
            this.getData();
        },
        getData() {
            this.$refs.table.getData(
                this.searchDate,
                this.dateChoice,
                this.searchVal,
                this.currentPage,
                this.pagesize,
                this.btnP
            );
        },
        // 单选时间
        clickitem(e) {
            e === this.dateChoice
                ? (this.dateChoice = '')
                : (this.dateChoice = e);
            this.getTableData();
        },

        // 时间输入框变化
        expireTime() {
            if (this.searchDate && this.searchDate.length > 0) {
                this.dateChoice = '';
            }
            this.getTableData();
        },
        // 分配部门数据
        distributionDep(data) {
            // console.log(data);
            if (data.length > 0) {
                this.choiseData = data.map((item) => {
                    return {
                        id: item.id,
                    };
                });
            }
        },
        // 分配部门(头部)
        distribution() {
            this.isDistribution = true;
            this.$refs.table.$data.isChiose = true;
        },
        // 分配部门弹窗
        tabDistributionDep(row) {
            this.tableRow = row;
            this.dialogDistribution = true;
        },
        // 提交分配
        subDistribution() {
            let data = {
                param: {
                    followDepartmentId: this.followDepartmentId,
                },
            };
            if (!this.followDepartmentId) {
                return this.$message.error('请选择分配部门');
            }
            if (this.tableRow.id) {
                data.param.lineDOList = [
                    {
                        id: this.tableRow.id,
                    },
                ];
            } else {
                data.param.lineDOList = this.choiseData;
            }
            allotDepartment(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.handleClose();
                    this.followDepartmentId = '';
                }
            });
        },
        // 详情
        onDetail(row) {
            this.$router.push({
                path: '/aliCluedetails',
                query: {
                    id: row.id,
                    departmentId: sessionStorage.getItem('departmentId'),
                },
            });
        },
        // 撤回弹窗
        onRevoke(row) {
            this.dialogRevoke = true;
            this.tableRow = row;
        },
        // 撤回
        subRevoke() {
            let data = {
                param: {
                    id: this.tableRow.id,
                    status: 13,
                },
            };
            updateClue(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.handleClose();
                }
            });
        },
        // 分配负责人弹窗
        onDistributionUser(row) {
            this.tableRow = row;
            this.followAdminId = row.followAdminId || '';
            this.dialogUser = true;
        },
        // 获取负责人
        getChargeUser() {
            let data = {
                param: {
                    departmentId: sessionStorage.getItem('departmentId'),
                },
            };
            roleList(data).then((res) => {
                this.chargeUser = res.data;
            });
        },
        telSearch(row) {
            this.tableRow = row;
            this.searchVal.name = row.contactContent;
            this.getTableData();
        },
        // 分配负责人
        subDistributionUser() {
            let data = {
                param: {
                    id: this.tableRow.id,
                    followAdminId: this.followAdminId,
                    status: 5,
                },
            };
            if (!this.followAdminId) {
                return this.$message.error('请选择负责人');
            }
            updateClue(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.handleClose();
                    this.followAdminId = '';
                }
            });
        },
        // 添加往来
        onDealings(row) {
            this.tableRow = row;
            this.dialogDealing = true;
            this.clueDealingList(row);
            // setTimeout(() => {
            //     this.$refs.adddealing.getData(row.id);
            // }, 0);
        },
        // 往来列表
        clueDealingList(row) {
            this.row = row;
            let data = {
                param: {
                    lineId: row.id,
                },
            };
            clueDealingList(data).then((res) => {
                res.data.list.forEach((item) => {
                    item.productClue = row.productClue;
                });
                this.$refs.dealings.$data.historyData = res.data.list;
                this.$refs.dealings.$data.data = row;
            });
        },
        // 往来添加
        dealingAdd(data) {
            data.param.lineId = this.tableRow.id;
            addClueDealing(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.clueDealingList(this.tableRow);
                }
            });
        },
        // 往来添加说明
        addExplain(data) {
            intercourseAdd(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('添加成功');
                    this.clueDealingList(this.tableRow);
                }
            });
        },
        // 分页
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getData();
        },
        handleSizeChange(val) {
            this.pagesize = val;
            this.getData();
        },
        // 关闭抽屉
        handleClose() {
            this.dialogDistribution = false;
            this.dialogRevoke = false;
            this.dialogUser = false;
            this.dialogDealing = false;
            this.isDistribution = false;
            this.$refs.table.$data.isChiose = false;
            this.getData();
        },
        searchEmpty() {
            this.searchVal = {
                followAdminId: '',
                name: '',
            };
            this.dateChoice = '';
            this.searchDate = '';
            this.getData();
        },
    },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.clue_box {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    .search-box {
        width: 100%;
        background: #fff;
        border-radius: 4px;
        margin-bottom: 16px;
        span {
            font-size: 12px;
            font-weight: 600;
            color: #000;
            margin: 0 16px;
        }
        .search-one {
            margin: 10px 0 12px 0;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            .left {
                button {
                    background: #2370eb;
                    border-radius: 2px;
                    border-color: #2370eb;
                    margin-right: 16px;
                }
                .el-range-editor--small.el-input__inner {
                    vertical-align: middle;
                    margin-left: 19px;
                }
            }
            .right {
                background: #2370eb;
                border-radius: 2px;
                border-color: #2370eb;
                margin-right: 16px;
            }
        }
    }
    .table-title {
        background: #fff;
        padding: 16px;
        p {
            font-size: 14px;
            font-weight: 400;
            display: flex;
            align-items: center;
            color: #333333;
            line-height: 32px;
            button {
                background: #fff;
                border-radius: 2px;
                padding: 0 7px 0 12px;
                height: 26px;
                line-height: 26px;
                color: #333;
                font-size: 12px;
                border-color: #d9d9d9;
                float: right;
                i {
                    font-size: 10px;
                    margin-right: 5px;
                }
            }
            .subBtn {
                background: #2370eb;
                border-radius: 2px;
                border-color: #2370eb;
                color: #fff;
                height: 32px;
                padding: 0 10px;
            }
        }
    }
    .table {
        flex: 1;
    }
    .page-box {
        background: #fff;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .clue_btn {
        margin-top: 10px;
        position: absolute;
        top: -50px;
        right: 0;
        span {
            display: inline-block;
            padding: 10px 20px;
            margin-left: 15px;
            border-top-left-radius: 3px;
            border-top-right-radius: 3px;
            background-color: #fff;
        }
    }
}
.clue_assist_body {
    padding: 42px 74px 100px;
    .user {
        padding-bottom: 16px;
        display: flex;
        align-items: center;
        .left-spot {
            display: inline-block;
            width: 8px;
            height: 8px;
            background: #2370eb;
            border-radius: 50%;
            margin-right: 20px;
            margin-left: -22px;
        }
    }
    .user:last-child {
        .left-spot {
            display: inline-block;
            width: 7px;
            height: 7px;
            background: #fff;
            border: 1px solid #2370eb;
            border-radius: 50%;
            margin-right: 20px;
            margin-left: -22px;
        }
    }
}
.but {
    width: 100%;
    height: 48px;
    border-top: 1px solid #e5e5e5;
    display: flex;
    align-items: center;
    background: #fff;
    justify-content: space-between;
    button {
        width: 72px;
    }
}

.dialog_info {
    width: 80%;
    margin: 25px auto 150px;
    text-align: center;
    .info {
        font-size: 14px;
        margin-bottom: 16px;
        text-align: left;
        label {
            display: inline-block;
            width: 65px;
            font-weight: bold;
            color: #333;
            margin-left: 2px;
        }
    }
}
/deep/.el-dialog__footer {
    padding: 0;
    text-align: right;
    height: 50px;
    line-height: 50px;
    border-top: 1px solid #eeeeee;
    padding-right: 18px;
}
/deep/ .el-textarea__inner {
    resize: none;
}
.search-two {
    margin-bottom: 10px;
}
/deep/ .el-radio-button__inner {
    width: 100%;
}
.dl_con {
    width: 80%;
    margin: 35px auto 100px;
    text-align: center;
    color: #333;
    font-weight: 600;
    font-size: 14px;
    i {
        font-weight: 400;
        font-size: 60px;
        color: #f5a623;
        display: block;
        margin-bottom: 20px;
    }
}
</style>
